//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/bootstrap";

$theme:#F7B3C8;
$progress-bar: #F7B3C8;
$white:#ffffff;
$black:#000000;
$darkgray: #363636;
$button: #F7B3C8;
$body: #4f4e4e;
$answer-btn-hover: #EE91AE;



.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #F7B3C8;
    --bs-btn-border-color: #F7B3C8;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #EE91AE3;
    --bs-btn-hover-border-color: #EE91AE;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #86D0CE;
    --bs-btn-active-border-color: #86D0CE;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #86D0CE;
    --bs-btn-disabled-border-color: #86D0CE;
}